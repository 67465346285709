import React from 'react'
import styled, { keyframes } from 'styled-components'
import SEO from 'structure/seo'
import Layout from 'structure/layout'

import { 
  Header, 
  HowWeCanHelp, 
  Process, 
  Partners, 
  Testimonials,
  SayHello,
  WhatWeDo,
} from 'partials'

import {
  Grapheme,
  media,
} from 'ui'

const Home = () => {
  return (
    <Layout>
      <SEO
        url='/'
      />

      <Header>
        <Content>
          <Title>
            We build. <span>You grow.</span>
          </Title>
          <Description>
            Lean digital product innovation that really works.
          </Description>
        </Content>
        <GraphemeBox>
          <GraphemeWrapper>
            <Grapheme color='#000' strokeWidth={2} />
          </GraphemeWrapper>
          <GraphemeWrapper>
            <Grapheme color='#000' strokeWidth={2} />
          </GraphemeWrapper>
        </GraphemeBox>
      </Header>

      <Partners />

      <WhatWeDo />

      <HowWeCanHelp />

      <Process />

      <Testimonials />

      <SayHello />
      
    </Layout>
  )
}

const graphemeToTop = keyframes`
  to {
    transform: translate3d(-2.5rem, -2.5rem, 0);
  }
`

const graphemeToBottom = keyframes`
  to {
    transform: translate3d(2.5rem, 2.5rem, 0);
  }
`

const GraphemeBox = styled.div`
  position: absolute;
  top: 50%;
  width: 20rem;
  height: 20rem;
  left: 50%;
  margin-left: -10rem;
  margin-top: -10rem;
  opacity: .08;

  ${media.lessThan('phone')`
    width: 16rem;
    height: 16rem;
    margin-left: -8rem;
    margin-top: -8rem;
  `}
`

const GraphemeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 20rem;
  height: 20rem;
  animation-timing-function: cubic-bezier(.36,.44,.25,1);
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  &:nth-child(1) {
    animation-name: ${graphemeToTop};
  }

  &:nth-child(2) {
    animation-name: ${graphemeToBottom};
  }

  svg {
    height: 100%;
    max-width: 100%;
  }

  ${media.lessThan('phone')`
    width: 16rem;
    height: 16rem;
  `}
`

const Content = styled.div`
  text-align: center;
  position: relative;
  z-index: 1;
`

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 6.6rem;
  color: var(--color-primary);
  font-family: var(--font-title);

  ${media.lessThan('phone')`
    font-size: 4.4rem;
    line-height: 1.1;

    span {
      display: block;
    }
  `}
`

const Description = styled.p`
  padding: 0;
  font-size: 2.4rem;
  color: var(--color-text);
  line-height: 1.6;
  margin: 1rem auto 0;

  ${media.lessThan('phone')`
    font-size: 2rem;
    line-height: 1.4;
  `}
`

export default Home
